<template>
    <div id="page_style" class="flex flex-col items-center w-full h-full bg-transparent overflow-hidden" autocomplete="off">
        <!-- Search input section -->
        <div class="search-banner w-full bg-cover bg-no-repeat py-24 min-h-[400px] bg-[position:50%_25%]">
            <!-- <div class=""> -->
                <vs-input v-bind:propDisplay="searchTerm" v-bind:placeholder="'Enter your search term...'" @search-change="onSearch"></vs-input>
            <!-- </div> -->
        </div>

        <!-- Results area on top of the list of publications -->
        <div class="flex w-full">
            <!-- Left sidebar section. Empty, just to keep symmetry -->
            <div id="id-side-bar" class="w-1/3 pt-0 px-6"></div>

            <!-- Results section -->
            <!-- <div class="w-2/3 p-2"> -->
                <!-- Display results if any -->
                <!-- <div v-if="results.length > 0" >
                    <div v-if="hasSearchTerm()" class="p-1 mb-0 text-sm bg-[#d8f4f7] rounded-lg" role="alert">
                        <span class="font-medium pl-5">Your search term </span> 
                        <span class="font-semibold">{{ "'" + stringSearchTerm + "'" }}</span> 
                        yielded <strong>{{ numFound }}</strong> results:
                    </div>
                </div> -->
                <!-- Display message if no results found -->
                <!-- <div v-else-if="results.length == 0" class="p-1 mb-0 text-sm bg-[#d8f4f7] rounded-lg" role="alert">
                        <span class="font-medium pl-5">Your search term </span> 
                        <span class="font-semibold">{{ "'" + stringSearchTerm + "'" }}</span> 
                        yielded <strong>0</strong> results:
                </div>
            </div> -->

            <!-- Section for Count of results -->
            <div class="w-2/3 p-2">
                <!-- Display message if a search term exists -->
                <div v-if="hasSearchTerm()" class="p-1 mb-0 text-sm bg-[#d8f4f7] rounded-lg" role="alert">
                    <span class="font-medium pl-5">Your search term </span> 
                    <span class="font-semibold">{{ "'" + stringSearchTerm + "'" }}</span> 
                    yielded <strong>{{ results.length > 0 ? numFound : 0 }}</strong> results:
                </div>
            </div>

        </div>

        <!-- Area with the list of facets (LEFT) and list of publications (RIGHT) -->
        <div class="flex flex-col md:flex-row w-full">
            <!-- LEFT: Sidebar with facets (will appear on TOP on small screens) -->
            <div id="id-side-bar" class="w-full md:w-4/12 2xl:w-3/12 text-sm p-4">
                <div id="externals">
                    <div v-for="(facetItems, key, index) in facets" v-bind:key="index" class="mb-0">
                        <facet-category v-bind:facetItems="facetItems" v-bind:filterName="key" @filter="onFilter"></facet-category>
                    </div>
                </div>
            </div>

            <!-- RIGHT: Main results section with pagination and active filters -->
            <div class="flex flex-col w-full md:w-8/12 2xl:w-9/12 p-4">
                <div v-if="activeFilterCategories && Object.keys(activeFilterCategories).length > 0" class="mb-4">
                    <span v-for="(values, key, index) in activeFilterCategories" v-bind:key="index" class="active-filter-items">
                        <!-- Active filter categories -->
                        <active-facet-category
                            v-bind:filterItems="values"
                            v-bind:categoryName="key"
                            @clear-facet-category="onClearFacetCategory"
                        ></active-facet-category>
                    </span>
                </div>
                <div class="results">
                    <!-- Pagination before search results -->
                    <PaginationComponent class="mb-5" v-bind:data="pagination" @menu-click="onMenuClick"></PaginationComponent>
                    <!-- Results section -->
                    <vs-result v-bind:datasets="results"></vs-result>
                    <!-- Pagination after search results -->
                    <PaginationComponent class="mt-5" v-bind:data="pagination" @menu-click="onMenuClick"></PaginationComponent>
                </div>
            </div>
        </div>

        <!-- Partner logos section -->
        <!-- <div class="container mx-auto mt-5">
            <div class="flex flex-wrap justify-center gap-8">
                <div class="flex flex-col items-center w-72">
                    <a target="_blank" href="https://www.re3data.org/repository/r3d100013400">
                        <img src="@/assets/site/img/re3-data-logo-mono.jpg" alt="re3 data logo" class="w-full" />
                    </a>
                </div>
                <div class="flex flex-col items-center w-96">
                    <a target="_blank" href="http://www.geosphere.at/">
                        <img src="@/assets/site/img/geosphere-austria-logo.jpg" alt="logo geosphere austria" class="w-full" />
                    </a>
                </div>
                <div class="flex flex-col items-center w-72">
                    <a target="_blank" href="https://www.base-search.net/Search/Results?q=coll:fttethysrdr&refid=dctablede">
                        <img src="@/assets/site/img/base_logo.png" alt="logo base" class="w-full" />
                    </a>
                </div>
            </div>
        </div> -->

        <!-- Partner logos section -->
        <div class="w-full mx-auto mt-5 px-4">
            <div class="flex flex-wrap justify-between items-center">
                <div class="flex justify-center w-full md:w-1/3 p-4">
                    <a target="_blank" href="https://www.re3data.org/repository/r3d100013400">
                        <img src="@/assets/site/img/re3-data-logo-mono.jpg" alt="re3 data logo" class="w-full max-w-[200px]" />
                    </a>
                </div>
                <div class="flex justify-center w-full md:w-1/3 p-4">
                    <a target="_blank" href="http://www.geosphere.at/">
                        <img src="@/assets/site/img/geosphere-austria-logo.jpg" alt="logo geosphere austria" class="w-full max-w-[400px]" />
                    </a>
                </div>
                <div class="flex justify-center w-full md:w-1/3 p-4">
                    <a target="_blank" href="https://www.base-search.net/Search/Results?q=coll:fttethysrdr&refid=dctablede">
                        <img src="@/assets/site/img/base_logo.png" alt="logo base" class="w-full max-w-[250px]" />
                    </a>
                </div>
            </div>
        </div>




    </div>
</template>

<script lang="ts">
import SearchViewComponent from "./search-view-component";
export default SearchViewComponent;
// Import Tailwind CSS
import "@/index.css";
</script>

<style scoped>
.search-banner {
    background-image: url('/src/assets/site/img/main-search-banner.jpg');
}


/* Additional Tailwind adjustments */
/* .bg-blue-custom { background-color: #06bcef; }
.text-mouse-grey { color: #6c6e6b; }
.card { 
    background-color: white; 
    border: 1px solid rgba(0, 0, 0, 0.125); 
    border-radius: 0.25rem; 
} */

/* Custom responsive styles for banner */
/* @media (max-width: 576px) {
    .banner {
        align-items: flex-start;
        height: 400px;
    }
} */
</style>
