<template>
    <!-- Loop through each document in results and create a key for each item -->
    <div v-for="document in results" :key="document.id">
        <!-- Card container with border and padding for each document -->
        <div class="rounded-none shadow-none border border-gray-200 p-4">
            <!-- Spacing between elements within the card -->
            <div class="">
                <!-- Title section with a link to the dataset page -->
                <h4>
                    <router-link
                        class="text-black font-bold hover:underline no-underline cursor-pointer"
                        :to="{ name: 'dataset', params: { datasetId: document.id } }"
                    >
                        {{ document.title_output }}
                    </router-link>
                </h4>
                <!-- DOI section; displayed only if an identifier exists -->
                <p v-if="document.identifier && document.identifier.length > 0">
                    <a
                        target="_blank"
                        :href="'https://doi.org/' + document.identifier[0]"
                        class="text-teal-500 text-sm"
                    >
                        {{ "https://doi.org/" + document.identifier[0] + " &#10148;" }}
                    </a>
                    &nbsp;
                    <!-- Display author information conditionally -->
                    <span v-if="document.author && document.author.length > 0" class="text-gray-500 pointer-events-none text-sm">
                        <!-- Single author -->
                        <span v-if="document.author.length === 1" >
                            {{ simplifyAuthor(document.author[0]) }}
                        </span>
                        <!-- Multiple authors (2-3), separated by semicolons -->
                        <span v-if="document.author.length > 1 && document.author.length < 3">
                            <span
                                v-for="(author, index) in document.author"
                                :key="index"
                            >
                                {{ simplifyAuthor(author) }}
                                <span v-if="index < document.author.length - 1">; </span>
                            </span>
                        </span>
                        <!-- More than three authors; first two followed by "et al." -->
                        <span v-if="document.author.length >= 3" >
                            <span
                                v-for="(author, index) in document.author.slice(0, 2)"
                                :key="index"
                            >
                                {{ simplifyAuthor(author) }}
                                <span v-if="index < 1">; </span>
                            </span>
                            et al.
                        </span>
                    </span>
                </p>
                <!-- Abstract and publication date section -->
                <p class="pt-2 line-clamp-2 relative overflow-hidden">
                    <!-- Publication date formatted by a conversion function -->
                    <!-- <span class="text-gray-500 text-sm" data-container="div" data-title="Publication date">
                        {{ convert(document.server_date_published) + ": " }}
                    </span> -->
                    <!-- Abstract text with clamping and ellipsis -->
                    <span class="text-sm">
                        {{ document.abstract[0] }}
                        <span class="absolute bottom-0 right-0 bg-white">...</span>
                    </span>
                </p>
                <!-- Document type and open access information -->
                <p class="space-x-3 pt-4 pb-2">
                    <!-- Publication date formatted by a conversion function -->
                    <span class="inline-block px-2 py-1 text-xs font-normal text-gray-700 italic bg-white rounded-md border border-gray-300 shadow-sm">
                        {{ "Published " + convert(document.server_date_published, $i18n.locale) }}
                    </span>
                    <!-- Document type label -->
                    <span class="inline-block px-2 py-1 text-xs font-normal text-white bg-gray-500 rounded shadow-sm" :title="$t('data_type')">
                        <i class="fas fa-file"></i> {{ document.doctype }}
                    </span>
                    <!-- Open access label, displayed only for specific licenses -->
                    <span
                        v-if="openAccessLicences.includes(document.licence)"
                        class="inline-block px-2 py-1 text-xs font-normal text-white bg-primary-tethys rounded shadow-sm"
                        :title="$t('license_open')"
                    >
                        <i class="fas fa-lock-open"></i>
                    </span>
                </p>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import VsResults from "./vs-result";
export default VsResults;
</script>

<style lang="scss" scoped>

// OLD STYLING WITH BULMA =============================================================================

// .card {
//     border-radius: 0;
//     /* rempve box-shadow */
//     box-shadow: none;
// }

// /* overflow for abstracts */
// .clamped {
//     line-height: 1.5;
//     overflow: hidden;
//     position: relative;
// }
// .clamped-2 {
//     /* Clamp to 2 lines, ie line-height x 2: */
//     max-height: 4.5em;
// }
// .ellipsis {
//     background: #fff;
//     bottom: 0;
//     position: absolute;
//     right: 0;
// }
// .fill {
//     background: #fff;
//     height: 100%;
//     position: absolute;
//     width: 100%;
// }

// /* zenodo */
// .label-success {
//     background-color: #5cb85c;
// }
// .label-default {
//     background-color: #777;
// }
// .label-info {
//     background-color: #6aa3d5;
// }
// .label-keyword {
//     background-color: #7e7e7e;
// }
// .label {
//     display: inline-block;
//     padding: 0.2em 0.12em 0.3em;
//     font-size: 75%;
//     color: #fff;
//     border-radius: 0.25em;
//     margin-right: 1.25em;
//     text-transform: uppercase;
//     font-weight: normal;
// }
// /* .record-elem .h4, record-elem h4 {
//     font-size: 18px;
// } */

// .record-elem p a {
//     color: #33cccc;
//     font-size: 14px;
// }
// /* .record-elem h4 a {
//     color: #000;
// } */

// .record-elem h4 a {
//     cursor: pointer;
//     color: #000;
//     text-decoration: none;
//     font-weight: bold;
//     /* margin-left: 10px; */
//     // font-size: 16px;
// }
// .record-elem h4 a:hover {
//     color: #777;
//     text-decoration: none;
// }
// /* .search-detail a:hover {
//     color: rgb(0, 128, 0);
// } */
// .record-elem .h4,
// .record-elem h4 {
//     // font-size: 16px;
//     letter-spacing: 0.05em;
//     // margin-top: 8px;
// }
// .record-detail h1,
// .record-detail p,
// .record-elem h4,
// .record-elem p,
// .well {
//     word-wrap: break-word;
// }
// .record-elem p span {
//     color: #000;
//     font-size: 14px;
// }
// .record-elem p span.disabled {
//     color: #7e7e7e;
//     // color: lightgray;
//     pointer-events: none;
// }
// .record-elem p span i {
//     color: #336699;
// }

// .post {
//     position: relative;
//     min-height: 1px;
//     padding-left: 15px;
//     padding-right: 15px;

//     box-sizing: border-box;
//     margin-bottom: 10px;
//     margin-top: 15px;
// }
/* .record-elem {
    border-top: 1px solid rgb(238, 238, 238);
} */
</style>
