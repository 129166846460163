<!-- detail-dataset.component.vue -->
<template v-if="datasetId">
    <div id="page_style" class="flex flex-col items-center w-full h-full bg-transparent overflow-hidden text-sm" autocomplete="off">
        <!-- Search input section -->
        <div class="search-banner w-full bg-cover bg-no-repeat py-24 min-h-[400px] bg-[position:50%_25%]">
            <vs-input v-bind:propDisplay="searchTerm" v-bind:placeholder="'Enter your search term...'" @search-change="onSearch"></vs-input>
        </div>

        <!-- Section that shows the dataset details once the data is loaded -->
        <section v-if="loaded" class="py-5">

            <div class="w-11/12 sm:w-11/12 md:w-11/12 lg:w-10/12 xl:w-9/12 mx-auto">

                <div class="flex flex-wrap">

                    <!-- Left content block displaying dataset main attributes -------------------------------------------------------------------------------- --> 
                    <div class="w-full lg:w-8/12 md:px-5 py-3">

                        <!-- First line of labels with publication date, document type and open access information -->
                        <div class="flex justify-between items-center pt-1 pb-2">
                            <!-- Publication date box located at the left -->
                            <span class="px-2 py-1 text-xs font-normal text-gray-700 italic bg-white rounded-md border border-gray-300 shadow-sm">
                                {{ $t('published') + " " + getSimpleDate(dataset.server_date_published) }}
                            </span>

                            <!-- Labels for Document and License type -->
                            <div class="flex space-x-3">
                                <!-- Document type label -->
                                <span 
                                    class="inline-block px-2 py-1 text-xs font-normal text-white bg-gray-500 rounded shadow-sm"
                                    :title="$t('data_type')"
                                >
                                    <i class="fas fa-file"></i> {{ dataset.type }}
                                </span>

                                <!-- Open Access label if applicable -->
                                <span v-if="dataset.hasLicenses()">
                                    <span v-for="license in dataset.licenses" :key="license.id">
                                        <span
                                            v-if="openAccessLicences.includes(license.name)"
                                            class="inline-block px-2 py-1 text-xs font-normal text-white bg-primary-tethys rounded shadow-sm"
                                            :title="$t('license_open')"
                                        >
                                            <i class="fas fa-lock-open" ></i>
                                        </span>
                                    </span>
                                </span>
                                <!-- Open Access label if applicable -->
                                <span v-if="!dataset.hasEmbargoPassed()">
                                    <span v-for="license in dataset.licenses" :key="license.id">
                                        <span
                                            v-if="openAccessLicences.includes(license.name)"
                                            class="inline-block px-2 py-1 text-xs font-normal text-white bg-red-800 rounded shadow-sm"
                                            :title="$t('dataset_under_embargo_icon')"
                                        >
                                            <i class="fa-solid fa-hourglass-half"></i>
                                        </span>
                                    </span>
                                </span>
                            </div>
                        </div>

                        <!-- Container for title, DOI, abstract, files -->
                        <!-- <div class="bg-white border shadow rounded p-4 mb-5"> -->
                        <div class="bg-white border shadow rounded p-4">

                            <!-- Section for TITLE -->
                            <span v-if="dataset.hasOwnProperty('titles')" class="flex flex-wrap py-3">
                                <span class="w-full font-bold text-black text-lg ">
                                    <span v-if="dataset.MainTitle?.language.toUpperCase() == $i18n.locale">
                                        <p>{{ dataset.MainTitle?.value }}</p>
                                    </span>
                                    <span v-else>
                                        <p v-if="dataset.hasTranslatedTitle()">{{ dataset.TranslatedTitle?.value }}</p>
                                        <p v-else>{{ dataset.MainTitle?.value }}</p>
                                    </span>
                                </span>
                            </span>

                            <!-- Section for AUTHOR -->
                            <div class="text-gray-700 pb-4">
                                <p v-if="dataset.authors.length > 0">
                                    <span class="flex flex-wrap gap-1 items-center">
                                        <span 
                                            v-for="(author, index) in authorsList" 
                                            :key="index" 
                                            class="flex items-center"
                                        >
                                            <!-- Author Name -->
                                            <span> {{ author.displayName }} </span>
                                            
                                            <!-- SVG Icon (only if ORCID ID exists) -->
                                            <a 
                                                v-if="author.identifier_orcid != null" 
                                                :href="'https://orcid.org/' + author.identifier_orcid" 
                                                target="_blank" 
                                                class="relative group px-1"
                                            >
                                                <img 
                                                    src="../../assets/site/img/ORCID-iD_icon_unauth_vector.svg" 
                                                    alt="ORCID Logo" 
                                                    class="w-4 h-4"
                                                />
                                                <span 
                                                    class="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-1 hidden group-hover:block bg-black text-white text-xs rounded px-2 py-1 whitespace-nowrap"
                                                >
                                                    {{ author.displayName + " - " + $t('visit_orcid') }}
                                                </span>
                                            </a>

                                            <!-- Separator -->
                                            <span v-if="index < authorsList.length - 1">{{ "; " }}</span>
                                        </span>
                                    </span>
                                </p>
                                <p v-else>-</p>
                            </div>

                            <!-- Section for Authors -->
                            <!-- <span class="flex text-gray-800 pb-3">
                                <p>{{ getAuthors() }}</p>
                            </span> -->

                            <!-- Section for dataset's DOI if available -->
                            <a v-if="dataset.identifier" target="_blank" :href="'https://doi.org/' + dataset.identifier.value" class="flex text-secondary-tethys hover:underline pb-5">
                                {{ 'https://doi.org/' + dataset.identifier.value }}
                            </a>
                            <!-- Section for dataset ABSTRACTS -->
                            <div v-if="dataset.hasOwnProperty('abstracts')" class="flex flex-wrap pb-10">
                                <div class="w-full">
                                    <span v-if="dataset.MainAbstract?.language.toUpperCase() == $i18n.locale">
                                        <p>{{ dataset.MainAbstract?.value }}</p>
                                    </span>
                                    <span v-else>
                                        <p v-if="dataset.hasTranslatedAbstract()">{{ dataset.TranslatedAbstract?.value }}</p>
                                        <p v-else>{{ dataset.MainAbstract?.value }}</p>
                                    </span>
                                </div>
                            </div>

                            <!-- Section for dataset FILES and their details -->
                            <div class="flex flex-wrap mb-8">
                                <div v-if="dataset.files.length > 0" class="w-full">

                                    <div class="relative">
                                        <!-- Files Table -->
                                        <table class="hidden md:table w-full border border-gray-300 mb-4 text-xs sm:text-sm">
                                            <thead>
                                                <tr class="bg-gray-100">
                                                    <th class="border px-4 py-2 text-left">{{ $t('download') }}</th>
                                                    <th class="border px-4 py-2 text-left">{{ $t('language') }}</th>
                                                    <th class="border px-4 py-2 text-left">{{ $t('file_extension') }}</th>
                                                    <th class="border px-4 py-2 text-left">{{ $t('size') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr 
                                                    v-for="file in dataset.files" 
                                                    :key="file.id" 
                                                    class="hover:bg-blue-50"
                                                >
                                                    <td 
                                                        class="sm:font-semibold border px-4 py-2 cursor-pointer hover:text-white hover:bg-primary-tethys truncate overflow-hidden whitespace-nowrap max-w-[250px]"
                                                    >
                                                        <a v-if="dataset.hasEmbargoPassed()"
                                                            target="_blank" 
                                                            :href="portal + file.id" 
                                                            class="block w-full h-full text-inherit hover:text-white"
                                                        >
                                                            {{ file.label }}
                                                        </a>
                                                        
                                                        <a v-else 
                                                            target="_blank" 
                                                            href="" 
                                                            class="block w-full h-full text-inherit hover:text-white"
                                                        >
                                                            {{ file.label }}
                                                        </a>
                                                        
                                                    </td>
                                                    <td v-if="getLanguage(dataset.language) == 'German'" class="border px-4 py-2"> {{ $t('german') }}</td>
                                                    <td v-else class="border px-4 py-2"> {{ $t('english') }}</td>
                                                    <td class="border px-4 py-2">{{ getExtension(file.path_name) }}</td>
                                                    <td class="border px-4 py-2">{{ formatSize(file.file_size) }}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <!-- Stacked layout for smaller screens -->
                                        <!-- Accordion with 1 item -->
                                        <Accordion class="block md:hidden pb-4" customClass="files-small-screen" :itemCount="1">
                                            <!-- Titles -->
                                            <template #title="{ index }">
                                                <p class="font-sans font-bold uppercase">{{ $t('files') }}</p>
                                            </template>

                                            <!-- Contents -->
                                            <template #content="{ index }">
                                                <div 
                                                    v-for="file in dataset.files" 
                                                    :key="file.id" 
                                                    class="border-b border-b-gray-300 mb-1 p-4"
                                                >
                                                    <div class="truncate mb-1">
                                                        <!-- <strong>Download:</strong> -->
                                                        <a 
                                                            target="_blank" 
                                                            :href="portal + file.id" 
                                                            class="text-primary-tethys font-bold hover:underline"
                                                        >
                                                            <i class="fas fa-file-download mr-1"></i>
                                                            {{ file.label }}
                                                        </a>
                                                    </div>
                                                    <div class="mb-1">
                                                        <strong>{{ $t('language') + ': '}}</strong> {{ getLanguage(dataset.language) }}
                                                    </div>
                                                    <div class="mb-1">
                                                        <strong>{{ $t('file_extension') + ': '}}</strong> {{ getExtension(file.path_name) }}
                                                    </div>
                                                    <div>
                                                        <strong>{{ $t('size') + ': '}}</strong> {{ formatSize(file.file_size) }}
                                                    </div>
                                                </div>
                                            </template>
                                        </Accordion>

                                        <!-- Embargo Overlay -->
                                        <div
                                            v-if="!dataset.hasEmbargoPassed()"
                                            class="absolute inset-0 bg-black bg-opacity-70 flex items-center justify-center text-white text-center p-4 rounded-lg"
                                        >
                                            <p class="text-lg font-semibold">
                                                <!-- This dataset is under embargo until {{ embargoEndDate }}.<br /> -->
                                                {{ $t('dataset_under_embargo') + ": [ " }} <span class="font-bold"> {{ getSimpleDate(dataset.embargo_date) + " ]"}} </span>   <br />
                                                {{ $t('meanwhile_access_restricted') }}
                                            </p>
                                        </div>

                                    </div>

                                    
                                    <!-- Table showing file details if the embargo has passed -->
                                    <!-- <template v-if="dataset.hasEmbargoPassed()"> -->
                                        <!-- Table layout for larger screens -->
                                        <!-- <table class="hidden md:table w-full border border-gray-300 mb-4 text-xs sm:text-sm">
                                            <thead>
                                                <tr class="bg-blue-100">
                                                    <th class="border px-4 py-2 text-left">{{ $t('download') }}</th>
                                                    <th class="border px-4 py-2 text-left">{{ $t('language') }}</th>
                                                    <th class="border px-4 py-2 text-left">{{ $t('file_extension') }}</th>
                                                    <th class="border px-4 py-2 text-left">{{ $t('size') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr 
                                                    v-for="file in dataset.files" 
                                                    :key="file.id" 
                                                    class="hover:bg-blue-50"
                                                >
                                                    <td 
                                                        class="sm:font-semibold border px-4 py-2 cursor-pointer hover:text-white hover:bg-primary-tethys truncate overflow-hidden whitespace-nowrap max-w-[250px]"
                                                    >
                                                        <a 
                                                            target="_blank" 
                                                            :href="portal + file.id" 
                                                            class="block w-full h-full text-inherit hover:text-white"
                                                        >
                                                            {{ file.label }}
                                                        </a>
                                                    </td>
                                                    <td v-if="getLanguage(dataset.language) == 'German'" class="border px-4 py-2"> {{ $t('german') }}</td>
                                                    <td v-else class="border px-4 py-2"> {{ $t('english') }}</td>
                                                    <td class="border px-4 py-2">{{ getExtension(file.path_name) }}</td>
                                                    <td class="border px-4 py-2">{{ formatSize(file.file_size) }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    
                                    </template> -->
                    
                                    <!-- Fallback message if embargo is still valid -->
                                    <!-- <span v-else>{{ $t('embargo_msg') }}</span> -->
                                </div>

                            </div>


                            <!-- Section for METHOD description -->
                            <!-- <div v-if="dataset.hasOwnProperty('abstracts')" class="flex flex-wrap mb-8">
                                <span class="w-full font-bold text-black uppercase mb-4">{{ $t('method') + ':' }}</span>
                                <span v-if="dataset.hasMethodsAbstract()" class="w-full justify-normal">
                                    {{ dataset.MethodsAbstract?.value }}
                                </span>
                                <span v-else>-</span>
                            </div> -->

                            <!-- Section for SERIES information -->
                            <!-- <div v-if="dataset.hasOwnProperty('abstracts')" class="flex flex-wrap mb-8">
                                <span class="w-full font-bold text-black uppercase mb-4">{{ $t('series_information') + ':' }}</span>
                                <span v-if="dataset.hasSeriesInformationAbstract()" class="w-full">
                                    <span v-if="dataset.SeriesInformationAbstract?.language.toUpperCase() == $i18n.locale">
                                        <p>{{ dataset.SeriesInformationAbstract?.value }}</p>
                                    </span>
                                    <span v-else>
                                        <p v-if="dataset.hasTranslatedSeriesInformationAbstract()">{{ dataset.TranslatedSeriesInformationAbstract?.value }}</p>
                                        <p v-else>{{ dataset.SeriesInformationAbstract?.value }}</p>
                                    </span>
                                </span>
                                <span v-else>-</span>
                            </div> -->
                            
                        </div>


                        <!-- Accordion with 1 item -->
                        <Accordion class="bg-white text-gray-700 rounded mb-5" :itemCount="1">
                            <!-- Titles -->
                            <template #title="{ index }">
                                <div class="font-bold text-black uppercase" v-if="index === 0">{{ $t('further_details') }}</div>
                                <!-- <div class="uppercase font-semibold" v-else-if="index === 1">{{ $t('technical_metadata') }}</div> -->
                                <!-- <div v-else>Title for Section 3</div> -->
                            </template>

                            <!-- Contents -->
                            <template #content="{ index }">
                                <div v-if="index === 0">
                                    <!-- Custom content for Section 1 -->
                                    <!-- Section showing FURTHER DETAILS -------------------------------- -->

                                    <!-- Section for METHOD description -->
                                    <div v-if="dataset.hasOwnProperty('abstracts')" class="flex flex-wrap mb-8 mt-3">
                                        <span class="w-full font-bold uppercase mb-4">{{ $t('method') + ':' }}</span>
                                        <span v-if="dataset.hasMethodsAbstract()" class="w-full justify-normal">
                                            <!-- {{ dataset.MethodsAbstract?.value }} -->
                                            <span v-if="dataset.MethodsAbstract?.language.toUpperCase() == $i18n.locale">
                                                <p>{{ dataset.MethodsAbstract?.value }}</p>
                                            </span>
                                            <span v-else>
                                                <p v-if="dataset.hasTranslatedMethodsAbstract()">{{ dataset.TranslatedMethodsAbstract?.value }}</p>
                                                <p v-else>{{ dataset.MethodsAbstract?.value }}</p>
                                            </span>
                                        </span>
                                        <span v-else>-</span>
                                    </div>

                                    <!-- Section for SERIES information -->
                                    <div v-if="dataset.hasOwnProperty('abstracts')" class="flex flex-wrap mb-8">
                                        <span class="w-full font-bold uppercase mb-4">{{ $t('series_information') + ':' }}</span>
                                        <span v-if="dataset.hasSeriesInformationAbstract()" class="w-full">
                                            <span v-if="dataset.SeriesInformationAbstract?.language.toUpperCase() == $i18n.locale">
                                                <p>{{ dataset.SeriesInformationAbstract?.value }}</p>
                                            </span>
                                            <span v-else>
                                                <p v-if="dataset.hasTranslatedSeriesInformationAbstract()">{{ dataset.TranslatedSeriesInformationAbstract?.value }}</p>
                                                <p v-else>{{ dataset.SeriesInformationAbstract?.value }}</p>
                                            </span>
                                        </span>
                                        <span v-else>-</span>
                                    </div>

                                    <!-- Section for PUBLICATION LANGUAGE -->
                                    <div class="flex flex-wrap mb-8">
                                        <span class="w-full font-bold uppercase mb-2">{{ $t('publication_language') + ':' }}</span>
                                        <span v-if="getLanguage(dataset.language) == 'Deutsch'"> {{ $t('german') }}</span>
                                        <span v-else> {{ $t('english') }}</span>
                                    </div>

                                    <!-- Section for EMBARGO -->
                                    <div class="flex flex-wrap mb-8">
                                        <span class="w-full font-bold uppercase mb-2">{{ $t('embargo') + ':' }}</span>
                                        <span v-if="dataset.embargo_date">{{ getHumanDate(dataset.embargo_date) }}</span>
                                        <span v-else>-</span>
                                    </div>

                                    <!-- Section for CONTRIBUTORS -->
                                    <div class="flex flex-wrap mb-8">
                                        <span class="w-full font-bold uppercase mb-2">{{ $t('contributor') + ':' }}</span>
                                        <span v-if="dataset.hasContributors()">{{ dataset.contributors.map((u) => u.full_name).join(", ") }}</span>
                                        <span v-else>-</span>
                                    </div>


                                    <!-- <div class="text-gray-700"> -->
                                        <!-- Section showing PUBLICATION LANGUAGE -->
                                        <!-- <p><span class=" font-semibold">{{ $t('publication_language') + ': ' }}</span> 
                                            <span v-if="getLanguage(dataset.language) == 'Deutsch'"> {{ $t('german') }}</span>
                                            <span v-else> {{ $t('english') }}</span>
                                        </p> -->
                                        <!-- Section showing EMBARGO -->
                                        <!-- <p><span class=" font-semibold">{{ $t('embargo') + ': '}}</span> 
                                            <span v-if="dataset.embargo_date">{{ getHumanDate(dataset.embargo_date) }}</span>
                                            <span v-else>-</span>
                                        </p> -->
                                        <!-- Section showing CONTRIBUTORS -->
                                        <!-- <p><span class=" font-semibold">{{ $t('contributor') + ': '}}</span> 
                                            <span v-if="dataset.hasContributors()">{{ dataset.contributors.map((u) => u.full_name).join(", ") }}</span>
                                            <span v-else>-</span>
                                        </p>
                                    </div> -->

                                </div>
                                <!-- <div v-else-if="index === 1"> -->
                                    <!-- Custom content for Section 2 -->
                                    <!-- <p>{{ $t('persistent_identifier') + ': ' + dataset.url }}</p>
                                    <p>{{ $t('status') + ': ' + dataset.server_state }}</p>
                                    <p v-if="dataset.hasOwnProperty('user')">{{ $t('posted_by') + ': ' + dataset.user.login }}</p>
                                    <p>{{ $t('editor') + ': ' + dataset.creating_corporation }}</p>
                                </div> -->
                            </template>
                        </Accordion>




                        <!-- Container for Citation information -->
                        <div class="bg-white border shadow rounded p-4 mb-4 relative">
                            
                            <!-- Citation header -->
                            <div class="flex justify-between items-center pb-2">
                                <p class="font-bold text-black uppercase py-1">{{ $t('citation') + ':' }}</p>
                                <!-- Copy button -->
                                <span
                                    @click="copyToClipboard"
                                    class="cursor-pointer px-2 py-1 text-xs font-normal text-gray-700 italic bg-gray-100 rounded-md border border-gray-300 shadow-sm hover:bg-gray-200"
                                    :title="$t('copy_citation')"
                                >
                                    <i class="fas fa-copy"></i>
                                </span>
                            </div>
                            <!-- Section showing citation -->
                            <div class="text-gray-700">
                                <p class="pb-2">
                                    {{ getCitation() }}
                                    <!-- Link to the dataset's DOI if available -->
                                    <a
                                        v-if="dataset.identifier"
                                        target="_blank"
                                        class="text-secondary-tethys hover:underline"
                                        :href="'https://doi.org/' + dataset.identifier.value"
                                    >
                                        ({{ "https://doi.org/" + dataset.identifier.value }})
                                    </a>
                                </p>
                            </div>
                        </div>

                    </div>

                    <!-- RIGHT content block displaying additional dataset details -------------------------------------------------------------------------------- --> 
                    <div class="w-full lg:w-4/12 py-3">
                        
                        <!-- First line for language selection -->
                        <div class="locale-changer flex justify-end items-center pt-1 pb-2">
                            <!-- Language switch spans separated by '/' -->
                            <span
                                class="px-2 py-1 text-xs font-normal text-gray-700 bg-white rounded-md border border-gray-300 shadow-sm"
                                :title="$t('select_details_language')"
                            >
                                <template v-for="(locale, index) in $i18n.availableLocales" :key="`locale-${locale}`">
                                    <span
                                        class="cursor-pointer"
                                        :class="{ 'font-bold text-secondary-tethys': locale === $i18n.locale }"
                                        @click="changeLocale(locale)"
                                    >
                                        {{ locale.toUpperCase() }}
                                    </span>
                                    <!-- Add '/' separator except for the last item -->
                                    <span v-if="index < $i18n.availableLocales.length - 1"> / </span>
                                </template>
                            </span>
                        </div>
                        
                        <!-- Container for minimap and coverage details -->
                        <div class="bg-white border shadow rounded p-4 mb-5">
                            <div>
                                <Minimap :bounds="dataset.Bounds" class="relative z-10" ></Minimap>
                            </div>
                            <!-- Section for Abdeckung/Coverage -->
                            <h3 class="text-center text-gray-700 text-xs uppercase font-bold py-2">{{ $t('coverage') }}</h3>
                            <!-- Accordion with several items -->                                                    
                            <Accordion customClass="coverage" :itemCount="coverageAttributes.length">
                                <!-- Titles -->
                                <template #title="{ index }">
                                    {{ $t(coverageAttributes[index].key) }}
                                </template>
                                <!-- Contents -->
                                <template #content="{ index }">
                                    <div v-if="coverageAttributes[index].values">
                                        <template v-for="(value, label) in coverageAttributes[index].values" :key="label">
                                            <!-- <p v-if="value">{{ $t(label) + ": " + value }}</p> -->
                                            <span v-if="value">
                                                <p v-if="coverageAttributes[index].key == 'geolocation'">
                                                    {{ $t(label) + ": " + value.toFixed(6) }}
                                                </p>
                                                <p v-else>
                                                    <span v-if="coverageAttributes[index].key == 'elevation' || coverageAttributes[index].key == 'depth'">
                                                        {{ $t(label) + ": " + Intl.NumberFormat('de-de').format(value) + " m." }}
                                                    </span>
                                                    <span v-else>
                                                        {{ $t(label) + ": " + value }}
                                                    </span>
                                                </p>
                                            </span>
                                        </template>
                                    </div>
                                    <p v-else>No data available</p>
                                </template>
                            </Accordion>

                            <!-- <div> -->
                                <!-- Section for Abdeckung/Coverage -->
                                <!-- <h3 class="text-center text-gray-700 text-xs uppercase font-bold py-2">{{ $t('coverage') }}</h3> -->
                                <!-- <p class="whitespace-pre-wrap text-xs">{{ dataset.Coverage }}</p> -->
                            <!-- </div> -->

                        </div>


                        <!-- Container for publication DETAILS -->
                        <div class="bg-white border shadow rounded p-4 mb-5">

                            <!-- Header of the details section  -->
                            <!-- <div class="uppercase font-bold text-black pb-5">details</div> -->
                            <div class="uppercase font-bold text-black pb-5">{{ $t('details')}} </div>

                            <!-- Section for the CREATORs -->
                            <div class="text-gray-700 pb-5">
                                <p class="uppercase font-semibold pb-3">{{ $t('creator') + ':' }} </p>
                                <p v-if="dataset.authors.length > 0">
                                    <span class="flex flex-wrap gap-1">
                                        <span 
                                            v-for="(author, index) in authorsList" 
                                            :key="index" 
                                            class="pb-2 flex-shrink-0"
                                            :title="$t('search_creator')"
                                        >
                                            <router-link
                                                :to="{ name: 'Search', params: { display: author.lastName, type: 'authors' } }"
                                                class="px-2 py-1 text-xs  text-primary-tethys rounded-md border border-gray-300 shadow-sm hover:bg-gray-200 transition-colors"
                                            >
                                                {{ author.displayName }}
                                            </router-link>
                                        </span>
                                    </span>
                                </p>
                                <p v-else>-</p>
                            </div>    

                            <!-- Section for the LICENSE information -->
                            <div class="text-gray-700 pb-5">
                                <p class="uppercase font-semibold pb-1">{{ $t('license') + ':' }}</p>
                                <p v-if="dataset.hasLicenses()">
                                    <label v-for="license in dataset.licenses" :key="license.id">
                                        <!-- Link to the appropriate Creative Commons license -->
                                        <span class="font-normal">
                                            <a v-if="license.name=='CC-BY-4.0'" target="_blank" class=" text-gray-700" :href="'https://creativecommons.org/licenses/by/4.0/'">
                                                <i class="fa-brands fa-creative-commons"></i><span class="text-secondary-tethys hover:underline pl-1">{{ license.name }}</span>
                                            </a>
                                            <a v-else target="_blank" class="text-gray-700" :href="'https://creativecommons.org/licenses/by-sa/4.0/'">
                                                <i class="fa-brands fa-creative-commons"></i><span class="text-secondary-tethys hover:underline pl-1">{{ license.name }}</span>
                                            </a>
                                        </span>
                                    </label>
                                </p>
                            </div>

                            <!-- Section for the KEYWORDS list -->
                            <div class="text-gray-700 pb-5">
                                <p class="uppercase font-semibold pb-3">{{ $t('keywords') + ':' }}</p>
                                <p v-if="dataset.hasOwnProperty('subjects')">
                                    <!-- Iterate through subjects and display them as router links -->
                                    <span v-if="accessNotFromDoi()" class="flex flex-wrap gap-1">
                                        <span v-for="(subject, index) in dataset.subjects" :key="subject.value" class="pb-2 flex-shrink-0">
                                            <router-link
                                                :to="{ name: 'Search', params: { display: subject.value, type: 'subjects' } }"
                                                class="px-2 py-1 text-xs text-gray-700 rounded-md border border-gray-300 shadow-sm hover:bg-gray-200 transition-colors"
                                                :title="$t('search_keyword')"
                                            >
                                                {{ subject.value }}
                                            </router-link>
                                        </span>
                                    </span>

                                    <span v-else class="flex flex-wrap gap-1">
                                        {{ dataset.subjects.map((u) => u.value).join(", ") }}
                                    </span>
                                </p>
                                <p v-else>-</p>
                            </div>

                            <!-- Section showing PROJECT information -->
                            <div class="text-gray-700 pb-5">
                                <p class="uppercase font-semibold pb-1">{{ $t('project') + ':' }}</p>
                                <p v-if="dataset.project != null">
                                    <span>{{ dataset.project.label }}</span> 
                                    <i class="fas fa-circle-info  text-primary-tethys hover:text-black pl-1" @click="showModal" :title="$t('project_details')"></i>

                                    <!-- Modal -->
                                    <div
                                        v-if="isShowModal"
                                        @click.self="closeModal"
                                        class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
                                        aria-hidden="true"
                                    >
                                        <div class="relative w-full max-w-xl p-5 bg-white rounded-lg shadow">
                                            <!-- Modal Header -->
                                            <div class="flex items-start justify-between p-4 mb-4 border-b rounded-t">
                                                <span class="text-sm font-bold text-gray-700 bg-white rounded-md border border-gray-300 shadow-sm px-2 py-1">
                                                    {{ dataset.project?.label }}
                                                </span>
                                                <!-- <p class="font-bold pr-5">{{ dataset.project?.label }}</p> -->
                                                <p class="font-semibold italic text-left pl-3 py-1">{{ dataset.project?.name }}</p>
                                                <button
                                                    @click="closeModal"
                                                    type="button"
                                                    class="text-gray-400 hover:text-gray-900 py-1"
                                                >
                                                    <svg
                                                        class="w-5 h-5"
                                                        fill="currentColor"
                                                        viewBox="0 0 20 20"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            fill-rule="evenodd"
                                                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                            clip-rule="evenodd"
                                                        ></path>
                                                    </svg>
                                                </button>
                                            </div>

                                            <!-- Modal Body -->
                                            <div class="p-4 text-justify">
                                                <!-- TODO: The project description has to be taken from the dataset whenever the Tethys API really provides this attribute !!! -->
                                                <p>
                                                    {{ dataset.project.description }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </p>
                                <p v-else>-</p>
                            </div>

                            <!-- Section for the PUBLISHER -->
                            <div class="text-gray-700 pb-4">
                                <p class="uppercase font-semibold pb-1">{{ $t('publisher') }}</p>
                                <p>{{ dataset.publisher_name }}</p>
                            </div>

                        </div>

                        <!-- Container for REFERENCES -->
                        <div class="bg-white border shadow rounded p-4 mb-5">
                            <!-- Section showing References -->
                            <div class="text-gray-700">
                                <p class="uppercase text-black font-bold pb-3">{{ $t('references')}}</p>
                                <!-- Section for references listed -->
                                <ul v-if="dataset.references.length> 0">
                                    <li v-for="(reference, i) in dataset.references" :key="reference.id" class="pb-2">
                                        <!-- Link to reference if it's a DOI or URL -->
                                        <span v-if="reference.type == 'DOI' || reference.type == 'URL'" >
                                            <!-- <p><i class="fa-solid fa-square-up-right text-primary-tethys pb-1"></i><span class="pl-1">{{ $t(reference.relation) + ":" }}</span></p> -->
                                            <p><i class="fa-solid fa-right-to-bracket text-primary-tethys pb-1"></i><span class="pl-1">{{ $t(reference.relation) + ":" }}</span></p>
                                            <a target="_blank" class="block text-sm italic text-secondary-tethys hover:underline pb-2 pl-4" :href="reference.value">
                                                {{ reference.label }}
                                            </a>
                                            
                                        </span>
                                        <span v-else class="text-gray-700 pb-2">
                                            {{ `${reference.relation} (${reference.type}): ${reference.value}` }}
                                        </span>
                                        <!-- <p v-if="dataset.references.length > 0 && i < dataset.references.length - 1" class="font-normal text-gray-700">--</p> -->
                                    </li>
                                </ul>
                                <p v-else>-</p>
                            </div>

                        </div>
        

                        <!-- Container for FURTHER DETAILS -->
                        <!-- <div class="bg-white rounded p-4 mb-3"> -->

                            <!-- Section showing FURTHER DETAILS -->
                            <!-- <div class="text-gray-700">
                                <p class="uppercase font-semibold pb-3">{{ $t('further_details')}}</p> -->
                                <!-- Section showing PUBLICATION LANGUAGE -->
                                <!-- <p><span class=" font-semibold">{{ $t('publication_language') + ': ' }}</span> 
                                    <span v-if="getLanguage(dataset.language) == 'Deutsch'"> {{ $t('german') }}</span>
                                    <span v-else> {{ $t('english') }}</span>
                                </p> -->
                                <!-- Section showing EMBARGO -->
                                <!-- <p><span class=" font-semibold">{{ $t('embargo') + ': '}}</span> 
                                    <span v-if="dataset.embargo_date">{{ getHumanDate(dataset.embargo_date) }}</span>
                                    <span v-else>-</span>
                                </p> -->
                                <!-- Section showing CONTRIBUTORS -->
                                <!-- <p><span class=" font-semibold">{{ $t('contributor') + ': '}}</span> 
                                    <span v-if="dataset.hasContributors()">{{ dataset.contributors.map((u) => u.full_name).join(", ") }}</span>
                                    <span v-else>-</span>
                                </p>
                            </div>

                        </div> -->



                        <!-- Container for technical metadata -->
                        <!-- <div class="bg-white border shadow rounded p-4 mb-5"> -->

                            <!-- <fwb-accordion class="shadow-sm" flush :open-first-item="false">
                                <fwb-accordion-panel >
                                    <fwb-accordion-header class="bg-gray-50 hover:bg-gray-100" >
                                        <p class="font-sans uppercase font-semibold">{{ $t('technical_metadata') + ':' }}</p>
                                    </fwb-accordion-header >
                                    <fwb-accordion-content > -->
                                        <!-- Section showing Technical metadata -->
                                        <!-- <div class="text-gray-700">
                                            <p>{{ $t('persistent_identifier') + ': ' + dataset.url}}</p>
                                            <p>{{ $t('status') + ': ' + dataset.server_state }}</p>
                                            <p v-if="dataset.hasOwnProperty('user')">{{ $t('posted_by') + ': ' + dataset.user.login }}</p>
                                            <p>{{ $t('editor') + ': ' + dataset.creating_corporation }}</p>
                                        </div>
                                    </fwb-accordion-content>
                                </fwb-accordion-panel> -->
            
                            <!-- </fwb-accordion> -->

                            <!-- <section> -->
                                <!-- Accordion with multiple items -->
                                <!-- <Accordion :items="accordionItems">
                                    <template v-slot="{ content, index }"> -->
                                        <!-- Custom content rendering for each item -->
                                        <!-- <div v-if="index === 0" class="text-gray-700">
                                            <p>{{ $t('persistent_identifier') + ': ' + dataset.url }}</p>
                                            <p>{{ $t('status') + ': ' + dataset.server_state }}</p>
                                            <p v-if="dataset.hasOwnProperty('user')">{{ $t('posted_by') + ': ' + dataset.user.login }}</p>
                                            <p>{{ $t('editor') + ': ' + dataset.creating_corporation }}</p>
                                        </div>
                                        <div v-else>
                                            {{ content }}
                                        </div>
                                    </template>
                                </Accordion> -->

                                
                                 <!-- Accordion with 2 items -->
                                 <Accordion class="pb-4" :itemCount="1">
                                    <!-- Titles -->
                                    <template #title="{ index }">
                                        <!-- <div class="uppercase font-semibold" v-if="index === 0">{{ $t('further_details') }}</div> -->
                                        <!-- <div class="uppercase font-semibold" v-else-if="index === 1">{{ $t('technical_metadata') }}</div> -->
                                        <!-- <div v-else>Title for Section 3</div> -->

                                        <div class="uppercase font-semibold">{{ $t('technical_metadata') }}</div>
                                    </template>

                                    <!-- Contents -->
                                    <template #content="{ index }">
                                        <!-- <div v-if="index === 0"> -->
                                            <!-- Custom content for Section 1 -->
                                            <!-- Section showing FURTHER DETAILS -->
                                            <!-- <div class="text-gray-700"> -->
                                                <!-- Section showing PUBLICATION LANGUAGE -->
                                                <!-- <p><span class=" font-semibold">{{ $t('publication_language') + ': ' }}</span> 
                                                    <span v-if="getLanguage(dataset.language) == 'Deutsch'"> {{ $t('german') }}</span>
                                                    <span v-else> {{ $t('english') }}</span>
                                                </p> -->
                                                <!-- Section showing EMBARGO -->
                                                <!-- <p><span class=" font-semibold">{{ $t('embargo') + ': '}}</span> 
                                                    <span v-if="dataset.embargo_date">{{ getHumanDate(dataset.embargo_date) }}</span>
                                                    <span v-else>-</span>
                                                </p> -->
                                                <!-- Section showing CONTRIBUTORS -->
                                                <!-- <p><span class=" font-semibold">{{ $t('contributor') + ': '}}</span> 
                                                    <span v-if="dataset.hasContributors()">{{ dataset.contributors.map((u) => u.full_name).join(", ") }}</span>
                                                    <span v-else>-</span>
                                                </p>
                                            </div>
                                        </div> -->
                                        <!-- <div v-else-if="index === 1"> -->
                                            <!-- Custom content for Section 2 -->
                                            <!-- <p>{{ $t('persistent_identifier') + ': ' + dataset.url }}</p>
                                            <p>{{ $t('status') + ': ' + dataset.server_state }}</p>
                                            <p v-if="dataset.hasOwnProperty('user')">{{ $t('posted_by') + ': ' + dataset.user.login }}</p>
                                            <p>{{ $t('editor') + ': ' + dataset.creating_corporation }}</p>
                                        </div> -->

                                        <div>
                                            <!-- Custom content for Section 2 -->
                                            <p>
                                                <span class="font-semibold">{{ $t('persistent_identifier') + ': '}}</span>
                                                {{ dataset.url }}
                                            </p>
                                            <p>
                                                <span class="font-semibold">{{ $t('status') + ': '}}</span>
                                                {{ dataset.server_state }}
                                            </p>
                                            <p v-if="dataset.hasOwnProperty('user')">
                                                <span class="font-semibold">{{ $t('posted_by') + ': '}}</span>
                                                {{ dataset.user.login }}
                                            </p>
                                            <p>
                                                <span class="font-semibold">{{ $t('editor') + ': '}}</span>
                                                {{ dataset.creating_corporation }}
                                            </p>
                                        </div>
                                    </template>
                                </Accordion>



                            <!-- </section> -->

                            <!-- Section showing Technical metadata -->
                            <!-- <div class="text-gray-700 pb-4">
                                <p class="uppercase font-semibold pb-3">{{ $t('technical_metadata') + ':' }}</p>
                                <p>{{ $t('technical_metadata') + ': ' + dataset.url}}</p>
                                <p>{{ $t('status') + ': ' + dataset.server_state }}</p>
                                <p v-if="dataset.hasOwnProperty('user')">{{ $t('posted_by') + ': ' + dataset.user.login }}</p>
                                <p>{{ $t('editor') + ': ' + dataset.creating_corporation }}</p>
                            </div> -->

                        <!-- </div> -->

                    </div>
                </div>
            </div>

        </section>

        <!-- Partner logos section -->
        <div class="w-full mx-auto mt-5 px-4">
            <div class="flex flex-wrap justify-between items-center">
                <div class="flex justify-center w-full md:w-1/3 p-4">
                    <a target="_blank" href="https://www.re3data.org/repository/r3d100013400">
                        <img src="@/assets/site/img/re3-data-logo-mono.jpg" alt="re3 data logo" class="w-full max-w-[200px]" />
                    </a>
                </div>
                <div class="flex justify-center w-full md:w-1/3 p-4">
                    <a target="_blank" href="http://www.geosphere.at/">
                        <img src="@/assets/site/img/geosphere-austria-logo.jpg" alt="logo geosphere austria" class="w-full max-w-[400px]" />
                    </a>
                </div>
                <div class="flex justify-center w-full md:w-1/3 p-4">
                    <a target="_blank" href="https://www.base-search.net/Search/Results?q=coll:fttethysrdr&refid=dctablede">
                        <img src="@/assets/site/img/base_logo.png" alt="logo base" class="w-full max-w-[250px]" />
                    </a>
                </div>
            </div>
        </div>


    </div>

</template>

<script lang="ts">
import Minimap from "@/components/Minimap.vue";
import Accordion from "@/components/Accordion/Accordion.vue";
import DatasetDetailComponent from "./dataset-detail.component";
export default DatasetDetailComponent;
</script>

<style scoped lang="scss">

.search-banner {
    background-image: url('/src/assets/site/img/main-search-banner.jpg');
}

// .accordion-header {
//   @apply flex justify-between items-center w-full p-40 font-medium text-left text-gray-900 bg-gray-100 border border-b-0 border-gray-200 rounded-t-lg focus:ring-4 focus:ring-gray-200;
// }
// .accordion-body {
//   @apply p-40 border border-gray-200;
// }


// // @import 'leaflet/dist/leaflet.css';
// .section {
//     font-size: 0.8rem;
//     padding: 0;
// }

// .card {
//     border-radius: 0;
//     /* Remove box-shadow for a flat design */
//     box-shadow: none;
// }

// .link-label {
//     color: #33cccc;
// }

// .label {
//     /* color: #363636; */
//     display: block;
//     font-size: 0.8rem;
//     font-weight: 700;
// }

// .label.uppercase {
//     text-transform: uppercase;
// }

// .normal.label {
//     font-weight: 400;
// }

// .column p span i {
//     color: #336699;
// }

// .table-header {
//     /* color: #363636; */
//     font-size: 0.8rem;
//     font-weight: 700;
//     background-color: #ccddf1;
// }
</style>
