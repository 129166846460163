import { Component, Vue, Prop } from "vue-facing-decorator";

@Component({
  name: "Accordion",
})
export default class Accordion extends Vue {
  @Prop({ type: Number, required: true }) itemCount!: number; // Number of accordion items
  @Prop({ type: String, default: '' }) customClass!: string;

  private activeIndex: number | null = null;

  toggleAccordion(index: number): void {
    this.activeIndex = this.activeIndex === index ? null : index;
  }

  isActive(index: number): boolean {
    return this.activeIndex === index;
  }
}