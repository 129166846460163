// import moment from "moment";
import dayjs from "dayjs";

// // SOLR Dataset original
// export interface Dataset {
//     abstract_additional: Array<string>;// OpenSearch: abstract: Array<string>
//     abstract_output: string;// -----
//     author: Array<string>;// EQUAL
//     author_sort: Array<string>;// -----
//     belongs_to_bibliography: boolean;// EQUAL
//     creating_corporation: string;// EQUAL
//     doctype: string;// EQUAL
//     geo_location: string;// EQUAL
//     id: number;// EQUAL
//     identifier: Identifier;// OpenSearch: identifier: Array<string>
//     language: string;// EQUAL
//     licence: string;// EQUAL
//     publisher_name: string;// EQUAL
//     server_date_published: Array<number>;// OpenSearch not array!
//     subject: Array<string>;// OpenSearch: subjectS
//     title_output: string;// EQUAL
//     year: number;// EQUAL
//     year_inverted: number;// EQUAL
// }

// OpenSearch Dataset
export interface Dataset {
    abstract: Array<string>;// OpenSearch: abstract: Array<string>
    // abstract_output: string;// ----- NOT in OpenSearch
    author: Array<string>;// EQUAL
    // author_sort: Array<string>;// ----- NOT in OpenSearch
    belongs_to_bibliography: boolean;// EQUAL
    creating_corporation: string;// EQUAL
    doctype: string;// EQUAL
    geo_location: string;// EQUAL
    id: number;// EQUAL
    // identifier: Identifier;// OpenSearch: identifier: Array<string>
    identifier: Array<string>// DIFF DATATYPE
    language: string;// EQUAL
    licence: string;// EQUAL
    publisher_name: string;// EQUAL
    // server_date_published: Array<number>;// OpenSearch string!
    server_date_published: string;// DIFF DATATYPE
    // subject: Array<string>;// OpenSearch: subjectS
    subjects: Array<string>;// DIFF DATATYPE
    title_output: string;// EQUAL
    year: number;// EQUAL
    year_inverted: number;// EQUAL

    title: string // Unique in OpenSearch
    title_additional: Array<string> // Unique in OpenSearch
    bbox_xmin: string // Unique in OpenSearch
    bbox_xmax: string // Unique in OpenSearch
    bbox_ymin: string // Unique in OpenSearch
    bbox_ymax: string // Unique in OpenSearch
    reference: Array<string> // Unique in OpenSearch
    abstract_additional: Array<string>;// Unique in OpenSearch
}


export class Suggestion {
    constructor(
        public value: string, // Store the text value returned by OpenSearch
        // Store the highlight: i.e. the text value with the emphasised term that generated that results by OpenSearch. 
        // In this way we can highlight the real term existing in the publication independently of how different was the inserted term used for the FUZZY search. e.g. "Vien" fuzzy matched with "Wien"
        public highlight: string,
        public type: SearchType, // Type of search element
    ) {}
    // value!: string;
    // type!: SearchType;
}

// export class Suggestion {
//     constructor(
//         public value: string,
//         public type: SearchType,
//     ) {}
//     // value!: string;
//     // type!: SearchType;
// }

export enum SearchType {
    Title = "title",
    Author = "author",
    Subject = "subjects", // ** !! The field has this name in OpenSearch!!
    Doctype = "doctype"
}

export class DbDataset {

    constructor(
        public id: string,
        public url: string,
        public contributing_corporation: string,
        public creating_corporation: string,
        public publisher_name: string,
        public embargo_date: string,
        public publish_id: number,
        public project_id: number,
        public type: string,
        public language: string,
        public server_state: string,
        public belongs_to_bibliography: boolean,
        public created_at: string,
        public server_date_modified: string,
        public server_date_published: string,
        public account_id: number,
        public editor_id: number,
        public reviewer_id: number,
        public preferred_reviewer: number,
        public preferred_reviewer_email: string,
        public reject_editor_note: string,
        public reject_reviewer_note: string,
        public reviewer_note_visible: string,
        public titles: Array<Title>,
        public abstracts: Array<Abstract>,
        public authors: Array<Author>,
        public contributors: Array<Author>,
        public user: Person,
        public subjects: Array<Subject>,
        public licenses: Array<License>,
        public references: Array<Reference>,
        public files: Array<DbFile>,
        public identifier: Identifier,
        public referenced_by: Array<Reference>,
        public coverage?: Coverage,
        public project?: Project,
    ) {}

    public hasTranslatedTitle(): boolean {
        if (this.titles.some((e) => e.type === TitleType.Translated)) {
            return true;
        } else {
            return false;
        }
    }

    public hasTranslatedAbstract(): boolean {
        if (this.abstracts.some((e) => e.type === "Translated")) {
            return true;
        } else {
            return false;
        }
    }

    public hasSeriesInformationAbstract(): boolean {
        if (this.abstracts.some((e) => e.type === AbstractType.Series_information && this.language == e.language)) {
            return true;
        } else {
            return false;
        }
    }

    public hasTranslatedSeriesInformationAbstract(): boolean {
        if (this.abstracts.some((e) => e.type === AbstractType.Series_information && this.language != e.language)) {
            return true;
        } else {
            return false;
        }
    }

    // public hasMethodsAbstract(): boolean {
    //     if (this.abstracts.some((e) => e.type === AbstractType.Methods)) {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // }

    public hasMethodsAbstract(): boolean {
        if (this.abstracts.some((e) => e.type === AbstractType.Methods && this.language == e.language)) {
            return true;
        } else {
            return false;
        }
    }

    public hasTranslatedMethodsAbstract(): boolean {
        if (this.abstracts.some((e) => e.type === AbstractType.Methods && this.language != e.language)) {
            return true;
        } else {
            return false;
        }
    }

    public hasContributors(): boolean {
        if (this.contributors.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    public hasLicenses(): boolean {
        if (this.licenses.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    public hasEmbargoPassed(): boolean {
        if (this.embargo_date === null) {
            return true; // COMMENT THIS FOR TESTING
        }
        const embargoDate = dayjs(this.embargo_date);
        const todayDate = dayjs();

        // FOR TESTING: ----------------------------------------------
            // const embargoDate = dayjs(this.server_date_published); // TESTING server_date_published is later than Created_at
            // const todayDate = dayjs(this.created_at); // TESTING Created_at is before server_date_published and server_date_modified
        // -----------------------------------------------------------

        // Embargo has passed on the day after the specified date
        if (embargoDate.isBefore(todayDate)) {
            return true;
        } else {
            return false;
        }
    }

    // // TESTING OPPOSITE LOGIC
    // public isEmbargoActive(): boolean {
    //     const embargoDate = this.embargo_date ? dayjs(this.embargo_date) : null;
    //     const todayDate = dayjs();

    //     // FOR TESTING: ----------------------------------------------
    //         // const embargoDate = dayjs(this.dataset.server_date_published); // TESTING server_date_published is later than Created_at
    //         // const today = dayjs(this.dataset.created_at); // TESTING Created_at is before server_date_published and server_date_modified
    //     // -----------------------------------------------------------
      
    //     // Return true if embargo date exists and is in the future
    //     return embargoDate ? embargoDate.isAfter(todayDate) : false;
    // }



    public get MainTitle(): Nullable<Title> {
        return this.titles.find((e) => e.type === TitleType.Main);
    }

    public get TranslatedTitle(): Nullable<Title> {
        return this.titles.find((e) => e.type === TitleType.Translated);
    }

    public get MainAbstract(): Nullable<Abstract> {
        return this.abstracts.find((e) => e.type === AbstractType.Abstract);
    }

    public get TranslatedAbstract(): Nullable<Abstract> {
        return this.abstracts.find((e) => e.type === AbstractType.Translated);
    }

    public get SeriesInformationAbstract(): Nullable<Abstract> {
        return this.abstracts.find((e) => e.type === AbstractType.Series_information && this.language == e.language);
    }

    public get TranslatedSeriesInformationAbstract(): Nullable<Abstract> {
        return this.abstracts.find((e) => e.type === AbstractType.Series_information && this.language != e.language);
    }

    // public get MethodsAbstract(): Nullable<Abstract> {
    //     return this.abstracts.find((e) => e.type === AbstractType.Methods);
    // }

    public get MethodsAbstract(): Nullable<Abstract> {
        return this.abstracts.find((e) => e.type === AbstractType.Methods && this.language == e.language);
    }

    public get TranslatedMethodsAbstract(): Nullable<Abstract> {
        return this.abstracts.find((e) => e.type === AbstractType.Methods && this.language != e.language);
    }

// public get CoverageElements(): Array<string> {
//     let elements: Array<string> = [];
//     if (this.coverage != undefined) {
//         elements.push("coordinates");
//         if (this.coverage.elevation_absolut || this.coverage.elevation_max || this.coverage.elevation_min ) {
//             elements.push("elevation");
//         }
//         if (this.coverage.depth_absolut || this.coverage.depth_max || this.coverage.depth_min ) {
//             elements.push("depth");
//         }
//         if (this.coverage.time_absolut || this.coverage.time_max || this.coverage.time_min ) {
//             elements.push("time");
//         }
//     }
//     return elements;
// }

// public get Dictionaries(): CoverageDictionary {
//     let dictionaries: CoverageDictionary = [];
//     if (this.coverage != undefined) {
//         if (this.coverage.elevation_absolut || this.coverage.elevation_max || this.coverage.elevation_min ) {
//             dictionaries.push({ elevation: { min: this.coverage.elevation_min, max: this.coverage.elevation_max, absolut: this.coverage.elevation_absolut} })
//         }
//         if (this.coverage.depth_absolut || this.coverage.depth_max || this.coverage.depth_min ) {
//             dictionaries.push({ depth: { min: this.coverage.depth_min, max: this.coverage.depth_max, absolut: this.coverage.depth_absolut} })
//         }
//         if (this.coverage.time_absolut || this.coverage.time_max || this.coverage.time_min ) {
//             dictionaries.push({ time: { min: this.coverage.time_min, max: this.coverage.time_max, absolut: this.coverage.time_absolut} })
//         }
//     }
//     return dictionaries;
// }


    /**
     * Generates a CoverageDictionary based on the available attributes of the coverage instance.
     * Only attributes that are truly present (not undefined or null) will be included.
     * 
     * @returns { CovAttributes } The resulting dictionary of coverage attributes.
     */
    public get CoverageAttributes(): CovAttributes {
        let attributes: CovAttributes = [];
        
        if (this.coverage) {

            attributes.push({
                geolocation: {
                    x_max: this.coverage.x_max,
                    x_min: this.coverage.x_min,
                    y_max: this.coverage.y_max,
                    y_min: this.coverage.y_min,
                }
            });

            // Helper function to check if at least one value is neither undefined nor null
            const hasValues = (values: (number | string | null | undefined)[]): boolean =>
                values.some(value => value !== undefined && value !== null);

            // Check and add elevation if present
            if (hasValues([this.coverage.elevation_min, this.coverage.elevation_max, this.coverage.elevation_absolut])) {
                attributes.push({
                    elevation: {
                        min: this.coverage.elevation_min || undefined,
                        max: this.coverage.elevation_max || undefined,
                        absolut: this.coverage.elevation_absolut || undefined
                    }
                });
            }

            // Check and add depth if present
            if (hasValues([this.coverage.depth_min, this.coverage.depth_max, this.coverage.depth_absolut])) {
                attributes.push({
                    depth: {
                        min: this.coverage.depth_min || undefined,
                        max: this.coverage.depth_max || undefined,
                        absolut: this.coverage.depth_absolut || undefined
                    }
                });
            }

            // Check and add time if present
            if (hasValues([this.coverage.time_min, this.coverage.time_max, this.coverage.time_absolut])) {
                attributes.push({
                    time: {
                        tMin: this.coverage.time_min || undefined,
                        tMax: this.coverage.time_max || undefined,
                        tAbsolut: this.coverage.time_absolut || undefined
                    }
                });
            }
        }

        return attributes;
    }

// NOT USED ANY MORE?
    public get Coverage(): string {
        if (this.coverage != undefined) {
            const xMin = this.coverage.x_min;
            const xMax = this.coverage.x_max;
            const yMin = this.coverage.y_min;
            const yMax = this.coverage.y_max;
            // const elevationAbsolut = this.coverage.elevation_absolut;

            // let geoLocation =
            //     "- SOUTH-BOUND LATITUDE: " +
            //     yMin +
            //     "\n" +
            //     "- WEST-BOUND LONGITUDE: " +
            //     xMin +
            //     "\n" +
            //     "- NORTH-BOUND LATITUDE: " +
            //     yMax +
            //     "\n" +
            //     "- EAST-BOUND LONGITUDE: " +
            //     xMax;

            let geoLocation =
                // "- SOUTH-BOUND LATITUDE: " +
                "- South-bound Latitude: " +
                yMin +
                "\n" +
                "- West-bound Longitude: " +
                xMin +
                "\n" +
                "- North-bound Latitude: " +
                yMax +
                "\n" +
                "- East-bound Longitude: " +
                xMax;

            // geoLocation += elevationAbsolut != null ? ` * ELEVATION ABSOLUT: ${elevationAbsolut}\n` : "";

            // ${elevationAbsolut ? ` * ELEVATION ABSOLUT: ${elevationAbsolut}\n` : ""} `;

            let elevation = "";
            if (this.coverage.elevation_max != null && this.coverage.elevation_min != null) {
                // elevation += "\n- ELEVATION MIN: " + this.coverage.elevation_min + " \n- ELEVATION MAX: " + this.coverage.elevation_max;
                elevation += "\n- Elevation Min.: " + this.coverage.elevation_min + " m\n- Elevation Max.: " + this.coverage.elevation_max  + " m";
            }
            if (this.coverage.elevation_absolut != null) {
                elevation += "\n- Elevation Absolut: " + this.coverage.elevation_absolut + " m";
            }
            // if (elevation != "") geoLocation += ("\n---" + elevation);

            let depth = "";
            if (this.coverage.depth_max != null && this.coverage.depth_min != null) {
                depth += "\n- Depth Min.: " + this.coverage.depth_min + " m\n- Depth Max.: " + this.coverage.depth_max + " m";
            }
            if (this.coverage.elevation_absolut != null) {
                depth += "\n- Depth Absolut: " + this.coverage.depth_absolut + " m";
            }
            // if (depth != "") geoLocation += depth;

            let time = "";
            if (this.coverage.time_max != null && this.coverage.time_min != null) {
                time += "\n- Time Min.: " + this.coverage.time_min + "\n- Time Max.: " + this.coverage.time_max;
            }
            if (this.coverage.time_absolut != null) {
                time += "\n- Time Absolut: " + this.coverage.time_absolut;
            }
            // if (time != "") geoLocation += time;

            // let elevation = "";
            // if (this.coverage.elevation_max != null && this.coverage.elevation_min != null) {
                // elevation += "\n* ELEVATION MIN: " + this.coverage.elevation_min + " \n* ELEVATION MAX: " + this.coverage.elevation_max;
            // }
            // if (this.coverage.elevation_absolut != null) {
            //     elevation += "\n* ELEVATION ABSOLUT: " + this.coverage.elevation_absolut;
            // }
            // if (elevation != "") geoLocation += elevation;

            // let depth = "";
            // if (this.coverage.depth_max != null && this.coverage.depth_min != null) {
            //     depth += "\n* DEPTH MIN: " + this.coverage.depth_min + "\n* DEPTH MAX: " + this.coverage.depth_max;
            // }
            // if (this.coverage.elevation_absolut != null) {
            //     depth += "\n* DEPTH ABSOLUT: " + this.coverage.depth_absolut;
            // }
            // if (depth != "") geoLocation += depth;

            // let time = "";
            // if (this.coverage.time_max != null && this.coverage.time_min != null) {
            //     time += "\n* TIME MIN: " + this.coverage.time_min + "\n* TIME MAX: " + this.coverage.time_max;
            // }
            // if (this.coverage.time_absolut != null) {
            //     time += "\n* TIME ABSOLUT: " + this.coverage.time_absolut;
            // }
            // if (time != "") geoLocation += time;

            if (elevation != "" || depth != "" || time != "" ) {
                return geoLocation + "\n ---" + elevation + depth + time;
            } else {
                return geoLocation + elevation + depth + time;
            }

            // return geoLocation;
        } else {
            return "";
        }
    }

    /* Provides the bounds of the publication for the Leaflet minimap */
    public get Bounds(): L.LatLngBoundsLiteral | string {
        if (this.coverage != undefined) { 
            return [
                [Number(this.coverage.y_min), Number(this.coverage.x_min)], // Southwest corner
                [Number(this.coverage.y_max), Number(this.coverage.x_max)]  // Northeast corner
            ];
        } else {
            return ""; 
        }
    }
    
}

type Nullable<T> = T | undefined;

type CovAttributes = Array<{ [key: string]: { x_max?: string; x_min?: string; y_max?: string; y_min?: string; min?: number | string; max?: number | string; absolut?: number | string; tMin?: string; tMax?: string; tAbsolut?: string} }>;

export enum AbstractType {
    Abstract = "Abstract",
    Methods = "Methods",
    Series_information = "Series_information",
    Technical_info = "Technical_info",
    Translated = "Translated",
    Other = " Other",
}

export enum TitleType {
    Main = "Main",
    Translated = "Translated",
}
export interface Title {
    id: number;
    type: string;
    value: string;
    language: string;
}

export interface Abstract {
    id: number;
    type: string;
    value: string;
    language: string;
}

export interface Author {
    id: number;
    academic_title: string;
    date_of_birth: string;
    email: string;
    first_name: string;
    last_name: string;
    name_type: string;
    full_name: string;
    identifier_orcid: string;
}

export interface Person {
    id: number;
    login: string;
    email: string;
    first_name: string;
    last_name: string;
    created_at: string;
}

export interface Subject {
    id: number;
    language: string;
    type: string;
    value: string;
    external_key: string;
    created_at: string;
    updated_at: string;
}

export interface Coverage {
    x_min: string;
    x_max: string;
    y_min: string;
    y_max: string;
    elevation_min?: number;
    elevation_max?: number;
    elevation_absolut?: number;
    depth_min?: number;
    depth_max?: number;
    depth_absolut?: number;
    time_min?: string;
    time_max?: string;
    time_absolut?: string;
}

export interface License {
    id: number;
    active: boolean;
    comment_internal?: string;
    desc_markup?: string;
    desc_text?: string;
    language: string;
    link_licence: string;
    link_logo?: string;
    link_sign?: string;
    mime_type: string;
    name_long: string; // "Creative Commons Attribution 4.0 International (CC BY 4.0)"
    name: string; // "CC-BY-4.0"
    pod_allowed: boolean;
    sort_order: number;
}

export interface Reference {
    id: number;
    document_id: number;
    type: string;
    relation: string;
    value: string;
    label: string;
    dataset: Dataset;
}

export interface Project {
    id: number;
    label: string; // "ALLG_FACHLICH"
    name: string; //	"Allgemein fachliche Arbeiten"
    description: string; //	"Allgemein fachlich interdisziplinäre Arbeiten"
    created_at: string;
    updated_at: string;
}

export class DbFile {
    constructor(
        public id: number,
        public document_id: number,
        public path_name: string,
        public label: string,
        public mime_type: string,
        public file_size: number,
        public visible_in_frontdoor: boolean,
        public visible_in_oai: boolean,
        public sort_order: Int16Array,
        public created_at: string,
        public updated_at: string,
        public hashvalues: Array<HashValue>,
        public comment?: string,
        public language?: string,
    ) {}

    public get HashValue(): Nullable<HashValue> {
        return this.hashvalues.find((e) => e.type === "md5");
    }
}

export interface HashValue {
    file_id: number;
    type: string;
    value: string;
}

export interface Identifier {
    created_at: string;
    dataset_id: number;
    id: number;
    status: string; //'findable'
    type: string; //'doi'
    updated_at: string; //'2023-03-09T09:48:28.000Z'
    value: string; //'10.24341/tethys.209'
}
